<template>
    <form v-if="pageContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="row col-12 p-0 justify-content-end">
            <div class="form-group">
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    @click="save"
                >
                    <i class="fas fa-save" />
                    Update
                </button>
            </div>
        </div>
        <div class="form-group col-12">
            <label for="articlesTitle">Conferences information title</label>
            <input
                id="articlesTitle"
                v-model="pageContent.value.conferences_info_title"
                :class="{
                    'is-invalid':
                        $v.pageContent.value.conferences_info_title.$error
                }"
                type="text"
                class="form-control"
            />
        </div>
        <div class="form-group col-12">
            <label for="conferencesTitle">More information title</label>
            <input
                id="conferencesTitle"
                v-model="pageContent.value.more_info_title"
                :class="{
                    'is-invalid': $v.pageContent.value.more_info_title.$error
                }"
                type="text"
                class="form-control"
            />
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from './content.mixin.js';

export default {
    validations: {
        pageContent: {
            value: {
                conferences_info_title: {
                    required
                },
                more_info_title: {
                    required
                }
            }
        }
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'medical-information';
    }
};
</script>
