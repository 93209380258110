<template>
    <form v-if="pageContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="row col-12 p-0 justify-content-end">
            <div class="form-group">
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    @click="save"
                >
                    <i class="fas fa-save" />
                    Update
                </button>
            </div>
        </div>
        <div class="form-group col-12">
            <label for="termsTitle">Page Title</label>
            <input
                id="termsTitle"
                v-model="pageContent.value.title"
                :class="{ 'is-invalid': $v.pageContent.value.title.$error }"
                type="text"
                class="form-control"
            />
        </div>
        <div class="d-flex flex-wrap">
            <div class="form-group col-12">
                <label>Text</label>
                <wysiwyg-editor v-model="pageContent.value.text" />
            </div>
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from './content.mixin.js';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    validations: {
        pageContent: {
            value: {
                title: {
                    required
                },
                text: {
                    required
                }
            }
        }
    },

    components: { WysiwygEditor },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'privacy-policy';
    }
};
</script>
