var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageContent.value)?_c('form',[_c('were-changes-alert',{attrs:{"were-changes":_vm.wereChanges},on:{"save":_vm.save}}),_c('div',{staticClass:"row col-12 p-0 justify-content-end"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success waves-effect waves-light",attrs:{"type":"button"},on:{"click":_vm.save}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Update ")])])]),(_vm.pageContent.value.home_tiles)?_c('div',_vm._l((_vm.pageContent.value.home_tiles),function(tile,index){return _c('div',{key:index,staticClass:"mb-4"},[_c('div',{staticClass:"form-group col-12"},[_c('h5',[_vm._v("Box "+_vm._s(index + 1))]),_c('label',{attrs:{"for":"homeTitle"}},[_vm._v("Box text")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(tile.title),expression:"tile.title"}],staticClass:"form-control",class:{
                        'is-invalid':
                            _vm.$v.pageContent.value.home_tiles.$each.$iter[
                                index
                            ].title.$error
                    },attrs:{"type":"text"},domProps:{"value":(tile.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(tile, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('image-input',{attrs:{"path-to-upload":"/content/image","path-to-get-image":"/images/content","label":"Box icon","error":_vm.$v.pageContent.value.home_tiles.$each.$iter[index]
                            .icon.$error},model:{value:(tile.icon),callback:function ($$v) {_vm.$set(tile, "icon", $$v)},expression:"tile.icon"}})],1)])}),0):_vm._e(),_c('div',{staticClass:"form-group col-12 mt-5"},[_c('h5',[_vm._v("Button 1")]),_c('label',{attrs:{"for":"homeTitle"}},[_vm._v("Button text")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageContent.value.contact_button.title),expression:"pageContent.value.contact_button.title"}],staticClass:"form-control",class:{
                'is-invalid':
                    _vm.$v.pageContent.value.contact_button.title.$error
            },attrs:{"id":"button1title","type":"text"},domProps:{"value":(_vm.pageContent.value.contact_button.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pageContent.value.contact_button, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('image-input',{attrs:{"path-to-upload":"/content/image","path-to-get-image":"/images/content","label":"Button icon","error":_vm.$v.pageContent.value.contact_button.icon.$error},model:{value:(_vm.pageContent.value.contact_button.icon),callback:function ($$v) {_vm.$set(_vm.pageContent.value.contact_button, "icon", $$v)},expression:"pageContent.value.contact_button.icon"}})],1),_c('div',{staticClass:"form-group col-12 mt-4"},[_c('h5',[_vm._v("Button 2")]),_c('label',{attrs:{"for":"homeTitle"}},[_vm._v("Button text")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageContent.value.edit_button.title),expression:"pageContent.value.edit_button.title"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.pageContent.value.edit_button.title.$error
            },attrs:{"id":"button2title","type":"text"},domProps:{"value":(_vm.pageContent.value.edit_button.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pageContent.value.edit_button, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('image-input',{attrs:{"path-to-upload":"/content/image","path-to-get-image":"/images/content","label":"Button icon","error":_vm.$v.pageContent.value.edit_button.icon.$error},model:{value:(_vm.pageContent.value.edit_button.icon),callback:function ($$v) {_vm.$set(_vm.pageContent.value.edit_button, "icon", $$v)},expression:"pageContent.value.edit_button.icon"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }