<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div v-if="currentContentComponent" class="card-body">
                        <component :is="currentContentComponent" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@config';
import ContentPages from '@views/contents';

export default {
    page: {
        title: 'Contents',
        meta: [{ name: 'description', content: config.description }]
    },

    components: {
        ...ContentPages
    },

    data() {
        return {
            title: 'Contents',
            items: [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: 'Contents'
                }
            ],
            currentContentComponent: '',
            contentsItems: [
                {
                    key: 'home',
                    text: 'Home',
                    component: 'content-home'
                },
                {
                    key: 'terms-of-use',
                    text: 'Terms Of Use',
                    component: 'content-terms-of-use'
                },
                {
                    key: 'privacy-policy',
                    text: 'Privacy Policy',
                    component: 'content-privacy-policy'
                },
                {
                    key: 'product-information',
                    text: 'Product Information',
                    component: 'content-product-information'
                },
                {
                    key: 'medical-information',
                    text: 'Medical Information',
                    component: 'content-medical-information'
                }
            ]
        };
    },

    mounted() {
        const currentKey = this.$route.params.key;
        const currentItem = this.contentsItems.find(
            item => item.key === currentKey
        );

        this.currentContentComponent = currentItem.component;
        this.title = currentItem.text;

        this.items = [...this.items, { text: currentItem.text, active: true }];
    }
};
</script>
