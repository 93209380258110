import ContentHome from './ContentHome';
import ContentTermsOfUse from './ContentTermsOfUse';
import ContentPrivacyPolicy from './ContentPrivacyPolicy';
import ContentProductInformation from './ContentProductInformation';
import ContentMedicalInformation from './ContentMedicalInformation';

export default {
    ContentHome,
    ContentTermsOfUse,
    ContentPrivacyPolicy,
    ContentMedicalInformation,
    ContentProductInformation
};
