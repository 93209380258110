var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageContent.value)?_c('form',[_c('were-changes-alert',{attrs:{"were-changes":_vm.wereChanges},on:{"save":_vm.save}}),_c('div',{staticClass:"row col-12 p-0 justify-content-end"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success waves-effect waves-light",attrs:{"type":"button"},on:{"click":_vm.save}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Update ")])])]),_c('div',{staticClass:"form-group col-12"},[_c('label',{attrs:{"for":"pageTitle"}},[_vm._v("Page title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageContent.value.page_title),expression:"pageContent.value.page_title"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.pageContent.value.page_title.$error
            },attrs:{"id":"pageTitle","type":"text"},domProps:{"value":(_vm.pageContent.value.page_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pageContent.value, "page_title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Explanation text")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageContent.value.explanation_text),expression:"pageContent.value.explanation_text"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.pageContent.value.explanation_text.$error
            },attrs:{"id":"explanationText","type":"text"},domProps:{"value":(_vm.pageContent.value.explanation_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pageContent.value, "explanation_text", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("PDF Section title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageContent.value.pdf_section_title),expression:"pageContent.value.pdf_section_title"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.pageContent.value.pdf_section_title.$error
            },attrs:{"id":"pdfTitle","type":"text"},domProps:{"value":(_vm.pageContent.value.pdf_section_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pageContent.value, "pdf_section_title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Video section title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageContent.value.video_section_title),expression:"pageContent.value.video_section_title"}],staticClass:"form-control",class:{
                'is-invalid':
                    _vm.$v.pageContent.value.video_section_title.$error
            },attrs:{"id":"videoTitle","type":"text"},domProps:{"value":(_vm.pageContent.value.video_section_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pageContent.value, "video_section_title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Contact section title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageContent.value.sending_section_title),expression:"pageContent.value.sending_section_title"}],staticClass:"form-control",class:{
                'is-invalid':
                    _vm.$v.pageContent.value.sending_section_title.$error
            },attrs:{"id":"contactTitle","type":"text"},domProps:{"value":(_vm.pageContent.value.sending_section_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pageContent.value, "sending_section_title", $event.target.value)}}})])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }