<template>
    <form v-if="pageContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="row col-12 p-0 justify-content-end">
            <div class="form-group">
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    @click="save"
                >
                    <i class="fas fa-save" />
                    Update
                </button>
            </div>
        </div>
        <div v-if="pageContent.value.home_tiles">
            <div
                v-for="(tile, index) in pageContent.value.home_tiles"
                :key="index"
                class="mb-4"
            >
                <div class="form-group col-12">
                    <h5>Box {{ index + 1 }}</h5>
                    <label for="homeTitle">Box text</label>
                    <input
                        v-model="tile.title"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid':
                                $v.pageContent.value.home_tiles.$each.$iter[
                                    index
                                ].title.$error
                        }"
                    />
                </div>
                <div class="form-group col-12">
                    <image-input
                        v-model="tile.icon"
                        path-to-upload="/content/image"
                        path-to-get-image="/images/content"
                        label="Box icon"
                        :error="
                            $v.pageContent.value.home_tiles.$each.$iter[index]
                                .icon.$error
                        "
                    />
                </div>
            </div>
        </div>
        <div class="form-group col-12 mt-5">
            <h5>Button 1</h5>
            <label for="homeTitle">Button text</label>
            <input
                id="button1title"
                v-model="pageContent.value.contact_button.title"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid':
                        $v.pageContent.value.contact_button.title.$error
                }"
            />
        </div>
        <div class="form-group col-12">
            <image-input
                v-model="pageContent.value.contact_button.icon"
                path-to-upload="/content/image"
                path-to-get-image="/images/content"
                label="Button icon"
                :error="$v.pageContent.value.contact_button.icon.$error"
            />
        </div>
        <div class="form-group col-12 mt-4">
            <h5>Button 2</h5>
            <label for="homeTitle">Button text</label>
            <input
                id="button2title"
                v-model="pageContent.value.edit_button.title"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid': $v.pageContent.value.edit_button.title.$error
                }"
            />
        </div>
        <div class="form-group col-12">
            <image-input
                v-model="pageContent.value.edit_button.icon"
                path-to-upload="/content/image"
                path-to-get-image="/images/content"
                label="Button icon"
                :error="$v.pageContent.value.edit_button.icon.$error"
            />
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from './content.mixin.js';
import ImageInput from '@components/elements/ImageInput';

export default {
    validations: {
        pageContent: {
            value: {
                home_tiles: {
                    $each: {
                        title: { required },
                        icon: { isUploaded: v => !!v && !!v.id }
                    }
                },
                contact_button: {
                    title: { required },
                    icon: { isUploaded: v => !!v && !!v.id }
                },
                edit_button: {
                    title: { required },
                    icon: { isUploaded: v => !!v && !!v.id }
                }
            }
        }
    },

    components: {
        ImageInput
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'home';
    }
};
</script>
