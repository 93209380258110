<template>
    <form v-if="pageContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="row col-12 p-0 justify-content-end">
            <div class="form-group">
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    @click="save"
                >
                    <i class="fas fa-save" />
                    Update
                </button>
            </div>
        </div>
        <div class="form-group col-12">
            <label for="pageTitle">Page title</label>
            <input
                id="pageTitle"
                v-model="pageContent.value.page_title"
                :class="{
                    'is-invalid': $v.pageContent.value.page_title.$error
                }"
                type="text"
                class="form-control"
            />
        </div>
        <div class="form-group col-12">
            <label>Explanation text</label>
            <input
                id="explanationText"
                v-model="pageContent.value.explanation_text"
                :class="{
                    'is-invalid': $v.pageContent.value.explanation_text.$error
                }"
                type="text"
                class="form-control"
            />
        </div>
        <div class="form-group col-12">
            <label>PDF Section title</label>
            <input
                id="pdfTitle"
                v-model="pageContent.value.pdf_section_title"
                :class="{
                    'is-invalid': $v.pageContent.value.pdf_section_title.$error
                }"
                type="text"
                class="form-control"
            />
        </div>
        <div class="form-group col-12">
            <label>Video section title</label>
            <input
                id="videoTitle"
                v-model="pageContent.value.video_section_title"
                :class="{
                    'is-invalid':
                        $v.pageContent.value.video_section_title.$error
                }"
                type="text"
                class="form-control"
            />
        </div>
        <div class="form-group col-12">
            <label>Contact section title</label>
            <input
                id="contactTitle"
                v-model="pageContent.value.sending_section_title"
                :class="{
                    'is-invalid':
                        $v.pageContent.value.sending_section_title.$error
                }"
                type="text"
                class="form-control"
            />
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from './content.mixin.js';

export default {
    validations: {
        pageContent: {
            value: {
                page_title: {
                    required
                },
                explanation_text: {
                    required
                },
                pdf_section_title: {
                    required
                },
                video_section_title: {
                    required
                },
                sending_section_title: {
                    required
                }
            }
        }
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'product-information';
    }
};
</script>
