import { mapActions } from 'vuex';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    validations: {},

    components: { WereChangesAlert },

    data() {
        return {
            contentKey: '',
            initValueStringify: {},
            pageContent: {},
            isMounted: false
        };
    },

    computed: {
        wereChanges() {
            return (
                JSON.stringify(this.pageContent.value) !==
                this.initValueStringify
            );
        }
    },

    async beforeMount() {
        try {
            const response = await this.getContent({ key: this.contentKey });

            this.pageContent = { ...response };
            this.initValueStringify = JSON.stringify(this.pageContent.value);
            this.isMounted = true;
        } catch (err) {
            console.error(err);

            this.$bvToast.toast('Something went wrong!', {
                title: 'Error!',
                variant: 'danger',
                solid: true
            });
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/getContent',
            updateContent: 'contents/updateContent'
        }),

        async save() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$bvToast.toast(
                    'Make sure you filled all required fields!',
                    {
                        title: 'Error!',
                        variant: 'danger',
                        solid: true
                    }
                );
                return;
            }

            try {
                await this.updateContent({
                    key: this.pageContent.key,
                    contentData: this.pageContent
                });

                this.initValueStringify = JSON.stringify(
                    this.pageContent.value
                );

                this.$bvToast.toast('Page has been updated.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                let message = '';

                if (err.response.data.errors) {
                    err.response.data.errors.forEach(error => {
                        message += error + '. ';
                    });
                } else {
                    message = 'Something went wrong!';
                }

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
};
